import type { Company } from "~/types";
export default defineNuxtRouteMiddleware(async (to, _from) => {
  const isCustomDomain = useIsCustomDomain();
  const slug = to.path.split("/").at(2) || "";
  const company = await $fetch(`/api/companies/bySlugFromCache/${slug}`);
  const domain = useRequestURL().hostname;

  // allow any lps to show up on the non-custom domain
  // but if custom domain is enabled, make sure it's only showing jobs for the company
  // that mataches the custom domain
  if (isCustomDomain.value) {
    if (!company || company.domain !== domain) {
      return navigateTo("/");
    }
  } else {
    if (!company || !company.domain) return;

    // add canonical link to the head
    // when visiting the lp on the hiretruckers site
    // so that we don't get dinged for SEO for duplicate content

    useHead(() => ({
      link: [
        {
          rel: "canonical",
          href: `https://${company.domain.replace(/\/$/, "")}${to.path}`,
        },
      ],
    }));
  }
});
